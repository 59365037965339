import React, { useEffect, useMemo, useState } from 'react'
import { Grid, Box, Stack, Typography, Divider, Chip } from '@mui/material'

import { useTranslation } from '@contexts/translation'
import DashboardLayout from '@components/common/Layout'
import { useAuth } from '@contexts/auth'
import useTableStyles from '@styles/table/table'
import { useTheme } from '@mui/material/styles'
import { projectsTopBarURL } from '@api/projects'
import { ArrowForward, CalendarToday, CorporateFare } from '@mui/icons-material'
import CommonModal from '@components/common/Modal/CommonModal'
import HeaderActionsTable from '@components/Header/Table'
import Welcome from '@components/Dashboard/DashboardCards/Welcome'
import Occupation from '@components/Dashboard/DashboardCards/Occupation'
import Leases from '@components/Dashboard/DashboardCards/Leases'
import Tenants from '@components/Dashboard/DashboardCards/Tenants'
import Expenses from '@components/Dashboard/DashboardCards/Expenses'
import Tasks from '@components/Dashboard/DashboardCards/Tasks'
import { useFetchData } from '@api/fetchData'
import Unpaid from '@components/Dashboard/DashboardCards/Unpaid'
import RentsCharges from '@components/Dashboard/DashboardCards/RentsCharges'
import FilterActionsSelectorChip from '@components/common/Filter/ActionsSelectorChip'
import API_URL from '@config/services'
import queryString from '@utils/queryString'
import Metric from '@components/Dashboard/DashboardCards/Metric'
import useChipStyles from '@styles/chip/chip'
import Link from 'next/link'
import routes from '@config/routes'

const Home = () => {
  const { dictionary, t } = useTranslation()
  const theme = useTheme()
  const classesTable = useTableStyles()
  const { organization, isAuthenticated, orgSettings } = useAuth()
  const [page, setPage] = useState(1)
  const currency = orgSettings?.currency_symbol || '€'
  const classesChip = useChipStyles()

  const initialQueries = {
    year: new Date().getFullYear()
  }
  const [queries, setQueries] = useState(initialQueries)
  const [tasksQueries, setTasksQueries] = useState({ is_logged: false })
  const operation = queries?.operation || null

  const [isSwitchingCompany, setIsSwitchingCompany] = useState(false)
  const { data: projectsList } = useFetchData(
    isAuthenticated && organization ? projectsTopBarURL : null
  )

  const projects =
    (projectsList &&
      projectsList.results.map(p => ({
        label: p.display,
        handleClick: () => {
          setQueries(queries => ({
            ...queries,
            operation: p.id
          }))
        }
      }))) ||
    []

  const date_options = [
    {
      label: dictionary.next_year,
      handleClick: () => {
        setQueries(prev => ({ ...prev, year: new Date().getFullYear() + 1 }))
      }
    },
    {
      label: dictionary.this_year,
      handleClick: () => {
        setQueries(prev => ({ ...prev, year: new Date().getFullYear() }))
      }
    },
    {
      label: dictionary.last_year,
      handleClick: () => {
        setQueries(prev => ({ ...prev, year: new Date().getFullYear() - 1 }))
      }
    },
    {
      label: new Date().getFullYear() - 2,
      handleClick: () => {
        setQueries(prev => ({ ...prev, year: new Date().getFullYear() - 2 }))
      }
    },
    {
      label: new Date().getFullYear() - 3,
      handleClick: () => {
        setQueries(prev => ({ ...prev, year: new Date().getFullYear() - 3 }))
      }
    },
    {
      label: new Date().getFullYear() - 4,
      handleClick: () => {
        setQueries(prev => ({ ...prev, year: new Date().getFullYear() - 4 }))
      }
    }
  ]

  const { data: occupancyData, isLoading: isLoadingOccupancy } = useFetchData(
    API_URL.STATS.GET_OCCUPANCY + '?' + queryString(queries)
  )

  const { data: incomeData, isLoading: isLoadingIncome } = useFetchData(
    API_URL.STATS.GET_INCOME + '?' + queryString(queries)
  )

  const tenantQueries = {
    status: 'active',
    ...queries
  }

  const { data: tenantData } = useFetchData(
    API_URL.TENANTS.GET_TENANTS_LIST() + '?' + queryString(tenantQueries)
  )

  const { data: leasesData } = useFetchData(
    API_URL.LEASES.GET_LEASES_LIST() + '?' + queryString(tenantQueries)
  )
  const fetchTasksURL =
    API_URL.TASKS.GET_TASK_LIST() + '?' + queryString(tasksQueries)

  const { data: indicatorData, isLoading: isLoadingIndicators } = useFetchData(
    API_URL.INDICATORS.GET_INDICATORS_LIST() + '?' + queryString(queries)
  )
  const { data: tasksData, isLoading: isTasksLoading } =
    useFetchData(fetchTasksURL)

  useEffect(() => {
    if (queries?.operation) {
      setTasksQueries(prev => ({
        ...prev,
        operation: queries.operation
      }))
    } else {
      setTasksQueries(prev => ({
        is_logged: false
      }))
    }
  }, [queries])

  return (
    <>
      <DashboardLayout title={dictionary.dashboard}>
        <Box className={classesTable.overflowingContainer}>
          <Grid container spacing={3}>
            <Welcome />
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                paddingBottom={1.5}
              >
                <Typography
                  variant="subtitle2"
                  padding={1}
                  onClick={() => setPage(1)}
                  sx={
                    page === 1
                      ? {
                          borderRadius: '10px',
                          backgroundColor: 'white',
                          color: theme.palette.primary.main,
                          cursor: 'pointer'
                        }
                      : { cursor: 'pointer' }
                  }
                  data-testid="Typography-0fdc8ae7-b8ba-420f-bbb0-093c8bfc0baa"
                >
                  {dictionary.operational}
                </Typography>
                <Typography
                  variant="subtitle2"
                  padding={1}
                  onClick={() => setPage(2)}
                  sx={
                    page === 2
                      ? {
                          borderRadius: '10px',
                          backgroundColor: 'white',
                          color: theme.palette.primary.main,
                          cursor: 'pointer'
                        }
                      : { cursor: 'pointer' }
                  }
                  data-testid="Typography-4ee48763-ec75-43f8-ae4d-b85b39bcf0bd"
                >
                  {dictionary.kpis}
                  <Chip
                    sx={{ fontSize: 10, padding: 0, marginLeft: 1 }}
                    size="small"
                    className={classesChip.helpLight}
                    label={'NEW'}
                  />
                </Typography>
                {/* <Tooltip title={dictionary.coming_soon}>
                  <Typography
                    variant="subtitle2"
                    data-testid="Typography-4ee48763-ec75-43f8-ae4d-b85b39bcf0bd"
                  >
                    {dictionary.executive}
                  </Typography>
                </Tooltip> */}
              </Stack>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" gap={3}>
                <FilterActionsSelectorChip
                  icon={<CorporateFare style={{ height: 22 }} />}
                  options={[
                    {
                      label: dictionary.operations,
                      handleClick: () => {
                        // setPage(1)
                        setQueries(prev => {
                          delete prev.operation
                          return { ...prev }
                        })
                      }
                    },
                    ...projects
                  ]}
                />

                <FilterActionsSelectorChip
                  icon={<CalendarToday style={{ height: 16 }} />}
                  options={[...date_options]}
                  defaultIndex={1}
                />
                {queries?.operation && (
                  <Link
                    href={`${routes.project.summary}/${queries?.operation}`}
                    sx={{ textDecoration: 'none' }}
                  >
                    <Stack
                      direction="row"
                      gap={1}
                      alignItems="center"
                      px={1}
                      sx={{
                        borderRadius: '8px',
                        cursor: 'pointer',
                        transition: 'all 0.3s ease-out',
                        '&:hover': { backgroundColor: 'primary.medium' }
                      }}
                    >
                      <Typography
                        fontWeight={500}
                        fontSize={12}
                        color={theme.palette.primary.main}
                      >
                        {t('dashboard_go_to_operation')}
                      </Typography>
                      <ArrowForward
                        fontSize="8"
                        sx={{
                          stroke: theme.palette.primary.main,
                          strokeWidth: 1.5
                        }}
                      />
                    </Stack>
                  </Link>
                )}
              </Stack>
            </Grid>

            {page === 1 && (
              <>
                <Grid item xs={12} md={4}>
                  <RentsCharges
                    data={incomeData}
                    isLoading={isLoadingIncome}
                    currency={currency}
                    queries={queries}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Unpaid data={incomeData} currency={currency} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Expenses data={incomeData} currency={currency} />
                </Grid>
                <Grid item sm={12} md={7}>
                  <Leases leasesData={leasesData} operation={operation} />
                </Grid>
                <Grid item sm={12} md={5}>
                  <Occupation
                    data={occupancyData}
                    isLoading={isLoadingOccupancy}
                  />
                </Grid>
                <Grid item xs={12} md={7}>
                  <Tenants data={tenantData} />
                </Grid>
                <Grid item xs={12} md={5}>
                  <Tasks
                    data={tasksData}
                    isLoading={isTasksLoading}
                    fetchTasksURL={fetchTasksURL}
                  />
                </Grid>
              </>
            )}

            {page === 2 && !indicatorData && (
              <>
                <Grid item sm={3}>
                  <Metric />
                </Grid>
                <Grid item sm={3}>
                  <Metric />
                </Grid>
                <Grid item sm={3}>
                  <Metric />
                </Grid>
                <Grid item sm={3}>
                  <Metric />
                </Grid>
              </>
            )}

            {page === 2 && indicatorData && indicatorData.data && (
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={12} container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {dictionary.operational}
                    </Typography>
                  </Grid>
                  {Object.keys(indicatorData.data.operational_metrics).map(
                    (key, index) => (
                      <Grid item xs={12} md={4} key={index}>
                        <Metric
                          indicator={
                            indicatorData.data.operational_metrics[key]
                          }
                        />
                      </Grid>
                    )
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6">{dictionary.tenants}</Typography>
                </Grid>
                {Object.keys(indicatorData.data.tenant_management).map(
                  (key, index) => (
                    <Grid item xs={12} md={4} key={index}>
                      <Metric
                        indicator={indicatorData.data.tenant_management[key]}
                      />
                    </Grid>
                  )
                )}

                <Grid item xs={12}>
                  <Typography variant="h6">
                    {dictionary.invoicing_and_payments}
                  </Typography>
                </Grid>
                {Object.keys(indicatorData.data.billing_and_payments).map(
                  (key, index) => (
                    <Grid item xs={12} md={4} key={index}>
                      <Metric
                        indicator={indicatorData.data.billing_and_payments[key]}
                      />
                    </Grid>
                  )
                )}

                <Grid item xs={12}>
                  <Typography variant="h6">{dictionary.financials}</Typography>
                </Grid>
                {Object.keys(indicatorData.data.income).map((key, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    <Metric indicator={indicatorData.data.income[key]} />
                  </Grid>
                ))}
                {Object.keys(indicatorData.data.expenses).map((key, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    <Metric indicator={indicatorData.data.expenses[key]} />
                  </Grid>
                ))}

                <Grid item xs={12}>
                  <Typography variant="h6">
                    {dictionary.financial_ratios}
                  </Typography>
                </Grid>
                {Object.keys(indicatorData.data.ratios_and_returns).map(
                  (key, index) => (
                    <Grid item xs={12} md={4} key={index}>
                      <Metric
                        indicator={indicatorData.data.ratios_and_returns[key]}
                      />
                    </Grid>
                  )
                )}
                <Grid item xs={12}>
                  <Typography variant="h6">{dictionary.valuation}</Typography>
                </Grid>
                {Object.keys(indicatorData.data.valuations_and_debt).map(
                  (key, index) => (
                    <Grid item xs={12} md={4} key={index}>
                      <Metric
                        indicator={indicatorData.data.valuations_and_debt[key]}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            )}
          </Grid>
        </Box>
      </DashboardLayout>

      <CommonModal
        open={isSwitchingCompany}
        title={dictionary.switch_company}
        onClose={() => setIsSwitchingCompany(false)}
        maxWidth={'sm'}
      >
        <HeaderActionsTable handleClose={() => setIsSwitchingCompany(false)} />
      </CommonModal>
    </>
  )
}

export default Home
