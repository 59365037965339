import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import DashboardCard from './DashboardCard'
import { useTheme } from '@mui/material/styles'
import { Stack } from '@mui/system'
import { MetricSkeleton } from '@components/common/Skeletons'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import RemoveIcon from '@mui/icons-material/Remove'
import numberWithCommas from '@utils/numberWithCommas'
import Dialog from '@mui/material/Dialog'
import PlaceHolder from '@components/common/PlaceHolder'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@contexts/auth'

export default function Metric(props) {
  const { indicator } = props
  const theme = useTheme()
  const { t } = useTranslation()
  const { orgSettings } = useAuth()

  const currency = orgSettings?.currency_symbol || '€'
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const translateIndicator = indicator => {
    switch (indicator?.code) {
      case 'occupancy_rate':
        return {
          name: t('indicators_occupancy_rate'),
          description: t('indicators_occupancy_rate_desc'),
          unit: '%'
        }
      case 'vacant_units':
        return {
          name: t('indicators_vacant_units'),
          description: t('indicators_vacant_units_desc'),
          unit: t('unit') + (indicator?.value > 1 && 's')
        }
      case 'cap_rate':
        return {
          name: t('indicators_cap_rate'),
          description: t('indicators_cap_rate_desc'),
          unit: '%'
        }
      case 'operating_expense_ratio':
        return {
          name: t('indicators_operating_expense_ratio'),
          description: t('indicators_operating_expense_ratio_desc'),
          unit: '%'
        }
      case 'net_profit_margin':
        return {
          name: t('indicators_net_profit_margin'),
          description: t('indicators_net_profit_margin_desc'),
          unit: '%'
        }
      case 'average_lease_duration':
        return {
          name: t('indicators_average_lease_duration'),
          description: t('indicators_average_lease_duration_desc'),
          unit: t('month')
        }
      case 'average_revenue_per_lease':
        return {
          name: t('indicators_average_revenue_per_lease'),
          description: t('indicators_average_revenue_per_lease_desc'),
          unit: currency
        }
      case 'average_contract_value':
        return {
          name: t('indicators_average_contract_value'),
          description: t('indicators_average_contract_value_desc'),
          unit: currency
        }
      case 'valuation':
        return {
          name: t('indicators_valuation'),
          description: t('indicators_valuation_desc'),
          unit: currency
        }
      case 'debt':
        return {
          name: t('indicators_debt'),
          description: t('indicators_debt_desc'),
          unit: currency
        }
      case 'debt_service':
        return {
          name: t('indicators_debt_service'),
          description: t('indicators_debt_service_desc'),
          unit: currency
        }
      case 'net_cash_flow':
        return {
          name: t('indicators_net_cash_flow'),
          description: t('indicators_net_cash_flow_desc'),
          unit: currency
        }
      case 'rental_income':
        return {
          name: t('indicators_rental_income'),
          description: t('indicators_rental_income_desc'),
          unit: currency
        }
      case 'other_income':
        return {
          name: t('indicators_other_income'),
          description: t('indicators_other_income_desc'),
          unit: currency
        }
      case 'operating_revenue':
        return {
          name: t('indicators_operating_revenue'),
          description: t('indicators_operating_revenue_desc'),
          unit: currency
        }
      case 'operating_expenses':
        return {
          name: t('indicators_operating_expenses'),
          description: t('indicators_operating_expenses_desc'),
          unit: currency
        }
      case 'net_operating_income':
        return {
          name: t('indicators_net_operating_income'),
          description: t('indicators_net_operating_income_desc'),
          unit: currency
        }
      case 'billing_volume':
        return {
          name: t('indicators_billing_volume'),
          description: t('indicators_billing_volume_desc'),
          unit: currency
        }
      case 'average_payment_time':
        return {
          name: t('indicators_average_payment_time'),
          description: t('indicators_average_payment_time_desc'),
          unit: t('day') + (indicator?.value > 1 && 's')
        }
      case 'late_payment_value':
        return {
          name: t('indicators_late_payment_value'),
          description: t('indicators_late_payment_value_desc'),
          unit: currency
        }
      default:
        return {
          name: indicator.name,
          description: indicator.description,
          unit: indicator.unit
        }
    }
  }

  if (!indicator)
    return (
      <DashboardCard>
        <MetricSkeleton />
      </DashboardCard>
    )

  return (
    <>
      <Grid
        container
        spacing={1}
        onClick={handleOpen}
        style={{ cursor: 'pointer' }}
      >
        <DashboardCard>
          <Grid container spacing={1} sx={{ minHeight: '125px' }}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={1}
                height="100%"
                style={{ position: 'relative' }}
              >
                <Grid item xs={12}>
                  <Stack
                    gap={3}
                    justifyContent="end"
                    alignItems="start"
                    height="100%"
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <Typography
                        fontSize={20}
                        color="secondary"
                        data-testid="Typography-89f1ca62-9ffc-4f2f-9c68-6587749b3e23"
                      >
                        {numberWithCommas(indicator?.value)}
                        <span
                          style={{
                            fontSize: '16px',
                            marginLeft: '4px',
                            color: '#7966eb'
                          }}
                        >
                          {translateIndicator(indicator)?.unit}
                        </span>
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <div style={{ position: 'absolute', top: 10, right: 10 }}>
                  {indicator?.change > 0 ? (
                    <ArrowUpwardIcon
                      style={{
                        color: indicator?.goal === 'min' ? 'red' : 'green'
                      }}
                    />
                  ) : indicator?.change < 0 ? (
                    <ArrowDownwardIcon
                      style={{
                        color: indicator?.goal === 'min' ? 'green' : 'red'
                      }}
                    />
                  ) : (
                    <RemoveIcon
                      style={{ color: theme.palette.action.disabled }}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                fontSize={15}
                data-testid="Typography-ff356072-e656-485f-8d8c-ee3a5d918efa"
              >
                {translateIndicator(indicator)?.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                fontSize={12}
                data-testid="Typography-ff356072-e656-485f-8d8c-ee3a5d918efa"
              >
                {translateIndicator(indicator)?.description}
              </Typography>
            </Grid>
          </Grid>
        </DashboardCard>
      </Grid>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <Typography
          variant="h6"
          component="div"
          style={{ margin: theme.spacing(2) }}
        >
          {t('indicators_historical_data')}
        </Typography>
        {indicator.past_data && indicator.past_data.length > 0 ? (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={indicator.past_data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                label={{
                  value: 'Date',
                  position: 'insideBottomRight',
                  offset: -10
                }}
              />
              <YAxis
                label={{
                  value: indicator.unit,
                  angle: -90,
                  position: 'insideLeft'
                }}
              />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="value"
                stroke={theme.palette.primary.main}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <Typography
            variant="subtitle1"
            style={{ margin: theme.spacing(2), textAlign: 'center' }}
          >
            <PlaceHolder src="/Placeholders/Keysy/Dépenses.png" />
            {t('indicators_historical_data_none')}
          </Typography>
        )}
      </Dialog>
    </>
  )
}
