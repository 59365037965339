import routes from '@config/routes'

import { useTranslation } from '@contexts/translation'
import { useTheme } from '@mui/material/styles'

import { Typography, Box, Grid } from '@mui/material'

import useStyles from '@styles/layout/layout'
import { useRouter } from 'next/router'
import { useAuth } from '@contexts/auth'
// import useIntercom from '@utils/useIntercom'
// import { useState } from 'react'
// import ZendeskWidget from '@components/Support/ZendeskWidget'
import FreshworksWidget from '@components/Support/FreshdeskWidget'
import YbugScript from '@components/Support/ybugWidget'

const Layout = ({
  title = 'Title',
  subtitle = null,
  description = null,
  tabs = null,
  filters = null,
  extraHeader = null,
  children
}) => {
  const classes = useStyles()
  const { dictionary } = useTranslation()
  const router = useRouter()
  const { user } = useAuth()
  const theme = useTheme()
  // const [intercomuser, setIntercomuser] = useState(null)

  const currentURL = window.location.href

  const isPageAccount = router.asPath.includes(routes.account.root)
  const isTenantPage =
    user.role === 'tenant' || currentURL.includes('tenant/preview')
  const isProjectsPage = router.asPath.includes(routes.projects.summary)
  const currentTab =
    currentURL.split('/').pop() === 'contracts'
      ? dictionary['contracts_']
      : currentURL.split('/').pop() === 'landlords'
      ? dictionary['landlords_']
      : currentURL.split('/').pop() === 'internal'
      ? ''
      : dictionary[currentURL.split('/').pop()]

  // useEffect(() => {
  //   const authenticatedUser = {
  //     id: user.id,
  //     name: user.first_name + ' ' + user.last_name,
  //     email: user.email
  //     // user_hash: "INSERT_HMAC_VALUE_HERE"
  //   }
  //   setIntercomuser(authenticatedUser)
  // }, [])

  // useIntercom(intercomuser)

  return (
    <>
      <Grid container className={classes.root}>
        {title !== dictionary.dashboard && (
          <Grid item xs={12} className={classes.headerContainer}>
            <Box
              className={
                isTenantPage
                  ? classes.overflowingContainerAccount
                  : !isPageAccount
                  ? classes.overflowingContainer
                  : classes.overflowingContainerAccount
              }
            >
              <Grid
                item
                xs={12}
                sx={{
                  borderRadius: theme =>
                    tabs
                      ? `${theme.spacing(1)} ${theme.spacing(1)} 0px 0px`
                      : theme.spacing(1),
                  display: isProjectsPage ? 'flex' : undefined,
                  flexDirection: isProjectsPage ? 'row' : undefined,
                  justifyContent: isProjectsPage ? 'space-between' : undefined
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    paddingBottom: theme.spacing(1)
                  }}
                >
                  {isPageAccount && (
                    <Box className={classes.informationContainer}>
                      <Typography
                        variant="h6"
                        color={'dark.main'}
                        fontWeight={600}
                        data-testid="Typography-e3306e19-3e8c-4475-8895-6a59b84719fb"
                      >
                        {subtitle ? `${title} - ` : title}
                        <span
                          style={{ color: theme.palette.primary.main }}
                          data-testid="span-f2d517a6-597a-4c6d-9887-34ac2ef16eb8"
                        >
                          {subtitle}
                        </span>
                      </Typography>

                      {description && (
                        <Typography
                          variant="caption"
                          color="text.light"
                          data-testid="Typography-2148077b-55ff-43b0-9104-eaa5bfecf7dd"
                        >
                          {description}
                        </Typography>
                      )}
                    </Box>
                  )}

                  {!isPageAccount && (
                    <Box className={classes.informationContainer}>
                      {subtitle && (
                        <Typography
                          variant="overline"
                          color="secondary"
                          data-testid="Typography-c4683d62-677c-4479-9afe-b5b525b2ac44"
                        >
                          {subtitle}
                        </Typography>
                      )}

                      <Typography
                        variant="h6"
                        color={'dark.main'}
                        fontWeight={600}
                        data-testid="Typography-76752b6e-064f-4392-a1bf-bdea1a4ec840"
                      >
                        {title}
                        {tabs && currentTab && (
                          <span
                            style={{ color: theme.palette.dark.main }}
                            data-testid="span-cf1745ab-de5f-465b-87ce-b54133ebd41c"
                          >
                            {' '}
                            - {currentTab}
                          </span>
                        )}
                      </Typography>

                      {description && (
                        <Typography
                          variant="caption"
                          color="text.light"
                          data-testid="Typography-2dcc79e4-b1dd-456a-9de6-ec5188b60d85"
                        >
                          {description}
                        </Typography>
                      )}
                    </Box>
                  )}

                  {extraHeader && <Box sx={{ mr: 3 }}>{extraHeader}</Box>}
                </Box>
                {/* {tabs && <Divider />} */}
                {tabs}
                {filters && (
                  <Grid item xs={12} className={classes.filters}>
                    {filters}
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        )}

        <Grid item xs={12} className={classes.childContainer}>
          <Box
            // className={
            //   !isPageAccount
            //     ? classes.overflowingContainer
            //     : classes.overflowingContainerAccount
            // }
            className={
              isTenantPage
                ? classes.overflowingContainerAccount
                : !isPageAccount
                ? classes.overflowingContainer
                : classes.overflowingContainerAccount
            }
          >
            {children}
          </Box>
        </Grid>
      </Grid>
      {/* <ZendeskWidget user={user} /> */}
      <FreshworksWidget user={user} />
      <YbugScript user={user} />
    </>
  )
}

export default Layout
